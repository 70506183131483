import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsPlayBtnFill } from 'react-icons/bs'
import { MdLocationPin } from 'react-icons/md'

import {
  useGetOnboardingCoachOptionsQuery,
  usePostOnboardingCoachSelectionMutation,
} from '../../../app/services/api'
import { RootState, store } from '../../../app/store'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import CoachInfo from '../../coaches/CoachInfo'
import OnboardingFooter from '../components/OnboardingFooter'
import OnboardingHeader from '../components/OnboardingHeader'
import { VideoModal } from '../components/VideoModal'
import { itemSelect } from '../onboardingSlice'
import TitleDescription from './TitleDescription'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { useE2Etest } from '../../../hooks/useE2Etest'
import { DEFAULT_LOCALE } from '../../../app/constants'
import { useAuth } from '../../../hooks/useAuth'

function isValidHttpUrl(s: string) {
  if (!s) {
    return false
  }

  try {
    new URL(s)
  } catch (e) {
    return false
  }
  return s.startsWith('http://') || s.startsWith('https://')
}

export const getCoachVideos = (
  t: any,
  coach: any
): Record<string, string>[] => {
  if (!coach?.profile) {
    return []
  }

  const defaultVideo = isValidHttpUrl(coach.profile.video)
    ? coach.profile.video
    : undefined

  const langSpecificVideos =
    coach.profile.videos?.filter(
      (video: any) => video.language && isValidHttpUrl(video.url)
    ) ?? []

  if (defaultVideo && langSpecificVideos.length <= 1) {
    return [{ language: t(DEFAULT_LOCALE), url: defaultVideo }]
  }

  return langSpecificVideos.map((video: any) => ({
    language: t(video.language) ?? video.language,
    url: video.url,
  }))
}

export const CoachSelectCard: React.FC<any> = ({
  coachBio,
  selected,
  onReadMore,
  onSelect,
  index,
  selectable = true,
}) => {
  const { t } = useLocale()
  const {
    isOpen: isVideoModalOpen,
    onClose: onVideoModalClose,
    onOpen: onVideoModalOpen,
  } = useDisclosure()

  const [videoUrl, setVideoUrl] = useState('')

  if (!coachBio.coach || !coachBio.coach.profile) return null

  const coachVideos = getCoachVideos(t, coachBio.coach)
  const openVideo = (url: string) => {
    setVideoUrl(url)
    onVideoModalOpen()
  }

  return (
    <WrapItem>
      <Box
        overflow="hidden"
        key={coachBio.coach._id}
        bg={
          selected === coachBio.coach.email
            ? 'var(--chakra-colors-primary-100)'
            : '#fff'
        }
        boxShadow="xl"
        // w={'full'}
        // maxHeight="fit-content"
        borderRadius="2xl"
        // minW="500px"
        maxW="400px"
      >
        <Flex alignItems="center" p={5} gridColumnGap={3}>
          <Avatar size="lg" src={coachBio?.coach?.profile?.picture} />
          <Flex direction="column" alignItems="flex-start">
            <Text fontWeight="700">{coachBio?.coach?.profile?.name}</Text>
            <Text fontSize="14px">{coachBio.title} </Text>
          </Flex>
        </Flex>
        <Divider />
        <Box p={5}>
          <Text noOfLines={4}> {coachBio.bio.summary} </Text>
        </Box>
        {coachBio.bio.highlights && (
          <Flex
            justifyContent="center"
            flexWrap="wrap"
            mb={2}
            // pl={5}
          >
            {coachBio.bio.highlights.slice(0, 3).map((highlight: string) => (
              <Text
                key={`highlight_${highlight}`}
                fontSize="14px"
                mx={2}
                fontWeight="700"
              >
                {highlight}
              </Text>
            ))}
          </Flex>
        )}

        {coachBio.coach.location && (
          <Flex alignItems="center" justifyContent="center">
            <MdLocationPin color="var(--chakra-colors-primary-600)" />
            <Text
              ml={1}
              color={'var(--chakra-colors-primary-600)'}
              fontWeight={'600'}
            >
              {coachBio.coach.location}
            </Text>
          </Flex>
        )}

        <Stack p={5} direction="column" spacing={2} w="full">
          {coachVideos.length > 0 &&
            coachVideos.map((video) => (
              <Flex
                justifyContent="center"
                userSelect="none"
                mt="0"
                transition=".1s"
                _active={{ transform: 'scaleX(.9)' }}
              >
                <Button
                  leftIcon={<BsPlayBtnFill />}
                  colorScheme="primary"
                  onClick={() => openVideo(video.url)}
                  variant="outline"
                  w="100%"
                >
                  {(coachVideos.length > 1 ? '[' + video.language + '] ' : '') +
                    t('watch_coach_intro')}
                </Button>
              </Flex>
            ))}
          <VideoModal
            url={videoUrl}
            onClose={onVideoModalClose}
            isOpen={isVideoModalOpen}
          />
          <Spacer />
          <Flex
            style={{ cursor: 'pointer' }}
            _hover={{ opacity: 0.4 }}
            alignItems="center"
            justifyContent="center"
            userSelect="none"
            transition=".1s"
            _active={{ transform: 'scaleX(.9)' }}
          >
            <Button
              leftIcon={<HiOutlineDocumentText />}
              colorScheme="primary"
              onClick={() => onReadMore(coachBio)}
              variant="outline"
              w="100%"
            >
              {t('read_more_coach_bio', { name: coachBio.coach.profile.name })}
            </Button>
          </Flex>
        </Stack>
        {selectable && (
          <>
            <Divider />
            <Flex
              p={5}
              _hover={{ background: '#eee' }}
              cursor="pointer"
              justifyContent="center"
              _active={{ background: '#ddd' }}
              userSelect="none"
              data-testid={`onboarding-coach-option-select-${index}`}
              onClick={() => onSelect(coachBio)}
            >
              <Text
                fontWeight="600"
                style={{ color: 'var(--chakra-colors-primary-500)' }}
              >
                {t('select')}
              </Text>
            </Flex>
          </>
        )}
      </Box>
    </WrapItem>
  )
}

const CoachSelectionQuestion: React.FC<any> = ({
  index,
  onSubmit,
  submitting = false,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const onboardingQuestions = useSelector(
    (state: RootState) => state.onboarding.questions
  )
  const onboardingUserEmail = useSelector(
    (state: RootState) => state.onboarding.email
  )
  const onboardingInterfaceLanguage = useSelector(
    (state: RootState) => state.onboarding.language
  )
  const departmentId = useSelector(
    (state: RootState) => state.onboarding.departmentId
  )

  const isLast = index === onboardingQuestions.length - 1

  const latestAnswers: any = store.getState().onboarding?.answers
  const [currentCoach, setCurrentCoach] = useState<any>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const question = onboardingQuestions[index]
  const { language, companyId } = useParams() as any
  const dispatch = useDispatch()
  const mixpanel = useMixpanel()
  const isE2Etest = useE2Etest()
  console.log('Coach selection, isE2Etest:', isE2Etest)
  const { data: coachBios, isLoading } = useGetOnboardingCoachOptionsQuery({
    email: user ? user.email : onboardingUserEmail,
    language,
    companyId,
    departmentId,
    allowTestCoach: isE2Etest,
  })
  const [coachSelected] = usePostOnboardingCoachSelectionMutation()
  const [selected, setSelected] = useState(
    latestAnswers[question.friendlyID] ?? ''
  )
  const toast = useToast()

  const onLocalSubmit = async () => {
    if (!selected) {
      toast({
        status: 'error',
        title: 'Error',
        description: t('please_select_coach'),
        isClosable: true,
      })
      return
    }

    dispatch(itemSelect({ questionId: question.friendlyID, answers: selected }))
    try {
      await coachSelected({
        userEmail: user ? user.email : onboardingUserEmail,
        coachEmail: selected,
        language: onboardingInterfaceLanguage,
      }).unwrap()
      mixpanel.track('onboarding_coach_selected', { coach: selected })
      onSubmit()
    } catch (err: any) {
      console.log('CoachPreselectedQuestion, err:', err)
      if (err.data?.message) {
        toast({
          status: 'error',
          title: 'Error',
          description: err.data.message,
          isClosable: true,
        })
      }
    }
  }

  return (
    <>
      {isOpen && (
        <CoachInfo isOpen={isOpen} onClose={onClose} coach={currentCoach} />
      )}
      <OnboardingHeader
        currentStep={index}
        totalSteps={onboardingQuestions.length - 1}
      />
      <Container maxW="container.lg" py={10}>
        <TitleDescription
          title={question.title}
          description={question.description}
        />
        <Wrap spacing="30px" justify="center">
          {isLoading ? (
            <Spinner />
          ) : (
            coachBios.map((coachBio: any, index: number) => (
              <CoachSelectCard
                coachBio={coachBio}
                selected={selected}
                key={`coach-option__${index}`}
                // data-testid={`onboarding-coach-option-${index}`}
                // data-test-coach-email={coachBio.coach.email}
                onReadMore={(coach: any) => {
                  setCurrentCoach(coach)
                  onOpen()
                }}
                index={index}
                onSelect={(coach: any) => setSelected(coach.coach.email)}
              />
            ))
          )}
        </Wrap>
        <OnboardingFooter
          onSubmit={onLocalSubmit}
          submitText={t('next')}
          submitting={submitting}
          isLastStep={index === onboardingQuestions.length - 1}
        />
      </Container>
      <div
        data-testid="onboarding-flow-test-hidden"
        data-onboarding-flow-test-type={question.type}
        data-onboarding-flow-test-index={index}
        data-onboarding-flow-test-id={question.friendlyID}
        data-onboarding-flow-test-islast={isLast}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default CoachSelectionQuestion
