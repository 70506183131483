import {
  Box,
  Center,
  Checkbox,
  Heading,
  IconButton,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useRadio,
  useRadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { addDays } from 'date-fns'
import moment from 'moment-timezone'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { DEFAULT_MAXIMUM_BOOKING_DAYS } from '../../app/constants'

import { BiInfoCircle } from 'react-icons/bi'
import {
  useGetAvailabilityMutation,
  useGetBookingConfigQuery,
  useGetExcludedDatesMutation,
  useGetSchedulerConfigQuery,
  useLazyGetCoachBioByIdQuery,
} from '../../app/services/api'
import { useLocale } from '../../hooks/useLocale'
import { useMixpanel } from '../../utils/MixpanelContext'
import CoachInfo from '../coaches/CoachInfo'
import CoachAvatar from '../profile/CoachAvatar'
import { AskPopUp } from './AskPopUp'
import { InfoPopover } from './components/InfoPopover'
import DatePicker from './DatePicker'
import { useE2Etest } from '../../hooks/useE2Etest'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const TimeItem = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  let centerProps = {
    p: '4',
    m: '1',
    borderRadius: 'lg',
    role: 'group',
    cursor: 'pointer',
    bg: 'gray.200',
    _hover: {
      bg: 'blue.500',
      color: 'white',
    },
  }

  if (props.noStyleCenter) {
    centerProps = {} as any
  }

  return (
    <Link as="label">
      <Center {...centerProps} {...checkbox}>
        <input {...input} />
        <Text fontSize="sm">{props.children}</Text>
      </Center>
    </Link>
  )
}

const Datetime = ({
  user,
  coach,
  duration,
  date,
  setDate,
  setAvailableDatesCount,
  setSlotsCount,
  setTime,
  onNext,
  timezone,
  language,
  inviteManager,
  setInviteManager,
  refetchAvailability,
  setRefetchAvailability,
  attempts,
  setAttempts,
  maxAttempts = 3,
  isAskPopUpEnabled,
  coachBios,
  setAnotherCoachSlot,
  setShouldShowAskPopUp,
  setFutureEventsCount,
  setRemainingSessionCount,
}: any) => {
  const { t } = useLocale()
  const mixpanel = useMixpanel()
  const [availableSlots, setAvailableSlots] = useState(null)
  const [extraCoachIds, setExtraCoachIds] = useState([])
  const [mergeAllCoachSlots, setMergeAllCoachSlots] = useState(false)
  const [
    triggerGetCoachBio,
    {
      data: coachBio,
      isLoading: isCoachBioLoading,
      isSuccess: isCoachBioSuccess,
    },
  ] = useLazyGetCoachBioByIdQuery()

  const {
    isOpen: isCoachBioOpen,
    onOpen: onCoachBioOpen,
    onClose: onCoachBioClose,
  } = useDisclosure()
  const [times, setTimes] = useState<any[]>()
  const [triedDates, setTriedDates] = useState<any[]>([])
  // const [isAskPopUpEnabled, setisAskPopUpEnabled] =
  //   useState(isAskPopUpEnabled)
  const [excludeDates, setExcludeDates] = useState<Date[]>([])
  const [maxDaysInFuture, setMaxDaysInFuture] = useState<number>(
    DEFAULT_MAXIMUM_BOOKING_DAYS
  )
  const [config, setConfig] = useState({
    minDate: new Date(),
    maxDate: addDays(new Date(), maxDaysInFuture),
  })
  const toast = useToast()
  const isE2Etest = useE2Etest()

  const { data: schedulerConfigData } = useGetSchedulerConfigQuery()
  const [getAvailability, { isLoading }] = useGetAvailabilityMutation()
  const [
    getExcludedDates,
    { data: excludeDatesData, isLoading: isExcludedLoading },
  ] = useGetExcludedDatesMutation()
  const { data: bookingConfig } = useGetBookingConfigQuery('config')
  const [inviteManagerModalShown, setInviteManagerModalShown] = useState(false)

  const showInviteManager = useFeatureFlagEnabled('show_invite_manager')

  useEffect(() => {
    if (isCoachBioSuccess) {
      setAnotherCoachSlot({
        profile: coachBio?.profile,
        // @ts-expect-error - id is not defined in the type
        id: coachBio?.id,
        // @ts-expect-error - email is not defined in the type
        email: coachBio?.email,
      })
    }
  }, [setAnotherCoachSlot, coachBio, isCoachBioSuccess])

  useEffect(() => {
    if (coachBios) {
      const coachIds = coachBios.map((coachBio: any) => coachBio?.coach?._id)
      setExtraCoachIds(coachIds ?? [])
    }
  }, [coachBios])

  // Get exlude dates from server as soon as it's mounted
  useEffect(() => {
    const params = {
      coachId: coach._id,
      duration: duration,
      timezone: user?.timezone ?? timezone,
    }
    if (!isExcludedLoading && !excludeDatesData) {
      console.log('Running getExcludedDates')
      getExcludedDates(params)
    }
    if (!date) {
      console.log('Running onSelectedDay')
      setDate(new Date())
      onSelectedDay(date)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bookingConfig?.config) {
      const now = new Date()
      const minDate = addDays(now, bookingConfig.config.minDaysInFuture)
      const maxDate = addDays(now, bookingConfig.config.maxDaysInFuture)

      setConfig({ minDate, maxDate })
    }
  }, [bookingConfig])

  // Set exclude dates based on excludeDatesData
  useEffect(() => {
    if (excludeDatesData) {
      const { data } = excludeDatesData
      // Convert excludeDatesData to Date type
      const dates = data.map((date: any) => new Date(date))
      setExcludeDates(dates)
      setAvailableDatesCount(dates ? maxDaysInFuture - dates.length : 0)
    }
  }, [excludeDatesData, maxDaysInFuture, setAvailableDatesCount])

  useEffect(() => {
    if (schedulerConfigData) {
      const { config } = schedulerConfigData
      setMaxDaysInFuture(config.maxDaysInFuture)
    }
  }, [schedulerConfigData])

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isAskPopUpEnabled && attempts >= maxAttempts) {
      mixpanel.track('multiple_slots_attempt_show_dialog')
      onOpen()
    }
  }, [attempts, isAskPopUpEnabled, onOpen, maxAttempts, mixpanel])

  const onSelectedDay = useCallback(
    async (date: any) => {
      if (date) {
        const d = new Date(date)
        const utcDate = moment([
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          0,
          0,
          0,
        ]).utcOffset(0, true)
        const params = {
          coachId: coach._id,
          duration: duration,
          date: moment(utcDate).toISOString(),
          timezone: user?.timezone ?? timezone,
          extraCoachIds: mergeAllCoachSlots ? extraCoachIds.join(',') : '',
          isE2Etest: isE2Etest ? '1' : '0',
        }
        const { data }: any = await getAvailability(params)
        console.log('{ data }')
        console.log({ data })
        setDate(date)
        if (data?.extra && mergeAllCoachSlots) {
          setAvailableSlots(data.extra)
        } else {
          setAvailableSlots(data?.data)
        }
        setRemainingSessionCount(data?.remainingSessionCount ?? 0)
        setFutureEventsCount(data?.futureEventsCount ?? 0)
        const slots = data?.data
        // For MixPanel
        setSlotsCount(slots ? slots.length : 0)
        const times: any[] = []
        slots.forEach((slot: string) => {
          const userTimezone = user?.timezone ?? timezone
          const start = moment(new Date(slot)).tz(userTimezone).format('HH:mm')
          const end = moment(new Date(slot))
            .tz(userTimezone)
            .add(duration, 'minutes')
            .format('HH:mm')
          times.push({
            label: `${start} - ${end}`,
            value: slot,
          })
        })
        times.sort((a, b) => a.value.localeCompare(b.value))
        setTimes(times)
        const formattedDate = moment(date).format('YYYY-MM-DD')
        const formattedDateWithSlots = `${formattedDate} -> ${
          times.length
        } (${times.map((time: any) => time.label).join(', ')})`
        setTriedDates((prev) => [...prev, formattedDateWithSlots])

        scrollToBottom()
        setAttempts((prev: number) => prev + 1)
      }
    },
    [
      extraCoachIds,
      mergeAllCoachSlots,
      setDate,
      getAvailability,
      coach,
      duration,
      user,
      timezone,
      setSlotsCount,
      setAttempts,
      isE2Etest,
      setFutureEventsCount,
    ]
  )

  console.log({ times })

  useEffect(() => {
    if (refetchAvailability) {
      onSelectedDay(date)
      setRefetchAvailability(false)
    }
  }, [refetchAvailability, onSelectedDay, date, setRefetchAvailability])

  const onTimeChange = useCallback(
    (time: string) => {
      setTime(time)
      onNext()
    },
    [onNext, setTime]
  )

  const timesEndRef = useRef() as MutableRefObject<HTMLDivElement>
  const scrollToBottom = () => {
    if (timesEndRef?.current)
      timesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const onInviteManagerCheckboxChange = (nextChecked: boolean) => {
    setInviteManager(nextChecked)
    if (!inviteManagerModalShown && nextChecked) {
      setInviteManagerModalShown(true)
      toast({
        status: 'info',
        description: t('invite_manager_extra_info'),
        isClosable: true,
      })
    }
  }

  const userTimezone = useMemo(() => {
    return user?.timezone ?? timezone
  }, [user, timezone])

  const coachBioHandler = (id: string) => {
    return coachBios.filter((element: any) => element?.coach?._id === id)[0]
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'time',
    onChange: onTimeChange,
  })
  const group = getRootProps()

  const renderTimeSlots = (slots: any[]) => {
    if (mergeAllCoachSlots) {
      return slots.map(({ slot, coachId }: any) => {
        const radio = getRadioProps({ value: slot })

        const startDate = moment(new Date(slot)).tz(userTimezone)
        const endDate = moment(new Date(slot))
          .tz(userTimezone)
          .add(duration, 'minutes')

        if (!startDate.isValid() || !endDate.isValid()) {
          return null
        }

        const start = startDate.format('h:mm A')
        const end = endDate.format('h:mm A')

        return (
          <Box
            key={slot + '_' + coachId}
            bg="gray.200"
            _hover={{ bg: 'blue.500' }}
            m="1"
            p="4"
            borderRadius="lg"
            pos="relative"
            onClick={() => {
              triggerGetCoachBio(coachId)
            }}
          >
            <Link onClick={onCoachBioOpen}>
              <CoachAvatar
                size="sm"
                hideUpload
                src={coachBioHandler(coachId)?.coach?.profile?.picture}
                styles={{
                  position: 'absolute',
                  top: '-5px',
                  left: 0,
                }}
              />
            </Link>
            <InfoPopover
              title={t('extra_slot')}
              trigger={
                <IconButton
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                  aria-label="Information"
                  icon={<BiInfoCircle />}
                  pos="absolute"
                  top="13%"
                  right="0%"
                />
              }
            >
              <Text>{t('another_coach_slot')}</Text>
            </InfoPopover>
            <Box flex="1">
              <TimeItem {...radio} noStyleCenter>
                {start} - {end}
              </TimeItem>
            </Box>
          </Box>
        )
      })
    }

    // Current coach
    return slots.map((slot: string) => {
      const radio = getRadioProps({ value: slot })
      const startDate = moment(new Date(slot)).tz(userTimezone)
      const endDate = moment(new Date(slot))
        .tz(userTimezone)
        .add(duration, 'minutes')

      if (!startDate.isValid() || !endDate.isValid()) {
        return null
      }

      const start = startDate.format('h:mm A')
      const end = endDate.format('h:mm A')

      return (
        <TimeItem
          key={slot}
          {...radio}
          onClick={() => setAnotherCoachSlot(null)}
        >
          {start} - {end}
        </TimeItem>
      )
    })
  }
  console.log({ excludeDatesData })
  return (
    <VStack spacing={4} {...group} data-testid="booking-datetime-selector">
      <DatePicker
        language={language}
        selectedDate={date}
        // Exclude unavailable days
        excludeDates={excludeDates}
        onSelect={onSelectedDay}
        dateFormat={'P'}
        minDate={config.minDate}
        maxDate={config.maxDate}
        inline={true}
      />
      <AskPopUp
        coachName={coach.profile?.name}
        isOpen={isOpen}
        keepLookingAndClose={() => {
          setAttempts(0)
          setShouldShowAskPopUp(false)
          mixpanel.track('appointment_time_preference_keep_looking')
          onClose()
        }}
        onClose={onClose}
        triedDates={triedDates}
        setMergeSlots={setMergeAllCoachSlots}
        setRefetchAvailability={setRefetchAvailability}
      />
      {coachBio && !isCoachBioLoading && (
        <CoachInfo
          isOpen={isCoachBioOpen}
          onOpen={onCoachBioOpen}
          onClose={onCoachBioClose}
          coach={coachBio}
        />
      )}
      {showInviteManager && user?.managerEmail && (
        <Box justifyContent={'center'} display={'flex'} mt={4}>
          <Checkbox
            colorScheme="red"
            borderColor="primary.500"
            checked={inviteManager}
            defaultChecked={inviteManager}
            onChange={(e) => onInviteManagerCheckboxChange(e.target.checked)}
          >
            {t('invite_manager_with_email', {
              manager_email: user.managerEmail,
            })}
          </Checkbox>
        </Box>
      )}
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        h="full"
        // {...rest}
      >
        {date && !isLoading && times && (
          <>
            <Heading p="4" as="h5" size="sm" w="240px">
              {moment(date).format('dddd, MMM Do')}
            </Heading>
            {times && times.length > 0 ? (
              renderTimeSlots(availableSlots ?? [])
            ) : (
              <Text mb={2}>{t('no_slots_available')}</Text>
            )}
            <span ref={timesEndRef}></span>
          </>
        )}
        {date && isLoading && (
          <Box w="240px" py={3}>
            <Spinner size="xl" />
          </Box>
        )}
      </Box>

      {!isExcludedLoading ? (
        <div data-testid="booking-flow-test-excluded-days-loaded" />
      ) : null}
      {!isLoading ? (
        <div data-testid="booking-flow-test-availability-loaded" />
      ) : null}
    </VStack>
  )
}

export default Datetime
