import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import posthog from 'posthog-js'

import { api } from '../../app/services/api'
import { persistor, type AppThunk } from '../../app/store'
import type { User } from '../../types/api'

type AuthState = {
  user: User | null
  token: string | null
  auth0Token: string | null
  authenticatedWith: 'local' | 'auth0' | null
}

const initialState: AuthState = {
  user: null,
  auth0Token: null,
  token: null,
  authenticatedWith: null,
}

const slice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      Object.assign(state, initialState)
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    saveUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    saveAuth0Token: (state, action: PayloadAction<string>) => {
      state.auth0Token = action.payload
    },
    setAuthMethod: (state, action: PayloadAction<'local' | 'auth0'>) => {
      state.authenticatedWith = action.payload
    },
  },
  selectors: {
    selectCurrentUser: (state) => state.user,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.signup.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        console.log(
          'extraReducers, getUserInfo.matchFulfilled, payload:',
          payload
        )
        state.user = payload
      }
    )
    builder.addMatcher(
      api.endpoints.postOnboardingData.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.postOnboardingProfileData.matchFulfilled,
      (state, { payload }) => {
        console.log(
          'extraReducers, postOnboardingProfileData.matchFulfilled, payload:',
          payload
        )
        state.token = payload.token
        state.user = payload.user
      }
    )
    builder.addMatcher(
      api.endpoints.postOnboardingInfo.matchFulfilled,
      (state, { payload }) => {
        console.log(
          'extraReducers, postOnboardingInfo.matchFulfilled, payload:',
          payload
        )
        state.user = payload.user
      }
    )
  },
})

export default slice.reducer

export const { updateUser } = slice.actions
export const { selectCurrentUser } = slice.selectors

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.logout())
  localStorage.clear()
  Cookies.remove('token')
  Cookies.remove('authenticatedWith')
  Cookies.remove('AmiEmail', { path: '/', domain: 'getami.co' })
  Cookies.remove('AmiPassword', { path: '/', domain: 'getami.co' })
  Cookies.remove('AmiEmail', { path: '/', domain: 'hupo.co' })
  Cookies.remove('AmiPassword', { path: '/', domain: 'hupo.co' })
  Cookies.remove('AmiEmail')
  Cookies.remove('AmiPassword')
  Cookies.remove('useV2')

  Sentry.setUser(null)
  mixpanel.track('logout_success')
  mixpanel.reset()
  posthog.reset()

  if (window._chatlio && window._chatlio.reset) {
    window._chatlio.reset()
  }
  await persistor.purge()
}

export const saveUserToken =
  (token: string, authMethod: 'local' | 'auth0' = 'local'): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAuthMethod(authMethod))
    Cookies.set('token', token)
    Cookies.set('authenticatedWith', authMethod)

    if (authMethod === 'auth0') {
      dispatch(slice.actions.saveAuth0Token(token))
    } else {
      dispatch(slice.actions.saveUserToken(token))
    }
  }
