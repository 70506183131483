import { createBrowserRouter, redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { ThemeProvider } from '@mui/material'
import { appTheme } from './theme/theme'

import AssessmentIntro from './features/assessment/AssessmentIntro'
import AssessmentResults from './features/assessment/AssessmentResults'
import AssessmentRouter from './features/assessment/AssessmentRouter'
import PasswordForgot from './features/auth/PasswordForgot'
import PasswordReset from './features/auth/PasswordReset'
import Signup from './features/auth/Signup'
import Bookings from './features/call/Bookings'
import InAppMeeting from './features/call/InAppMeeting'
import Chat from './features/chat/Chat'
import SessionReview from './features/customer/SessionReview'
import UserNotesWithSessionNotes from './features/customer/UserNotesWithSessionNotes'
import AppDistribution from './features/distribution/AppDistribution'
import AppDistributionApk from './features/distribution/AppDistributionApk'
import HupoHighlightsEdit from './features/hupohighlights/HupoHighlightsEdit'
import HupoHighlightsEditSuccess from './features/hupohighlights/HupoHighlightsEditSuccess'
import HupoHighlightsVerify from './features/hupohighlights/HupoHighlightsVerify'
import HupoPerspectivesPage from './features/hupoperspectives/HupoPerspectivesPage'
import { ResourcesLibraryPage } from './features/library/ResourcesLibrary'
import OnboardingFlow from './features/onboarding/OnboardingFlow'
import OnboardingLoadingScreen from './features/onboarding/OnboardingLoadingScreen'
import { PeerAssessmentPage } from './features/peer-assessment/PeerAssessmentPage'
import UserProfile from './features/profile/UserProfile'
import AssessmentReport from './features/report/AssessmentReport'
import AssessmentReportPDF from './features/report/AssessmentReportPDF'
import Book from './features/scheduler/Book'
import BookPublic from './features/scheduler/BookPublic'
import PostSessionUserFeedback from './features/scheduler/PostSessionUserFeedback'
import SessionSummaryFeedback from './features/session-summary/SessionSummaryFeedback'
import { Stats } from './features/stats/Stats'
import Support from './features/support/Support'
import { FeedbackSurvey } from './features/survey/FeedbackSurvey'
import OnboardingLink from './features/unified/OnboardingLink'
import UnifiedLink from './features/unified/UnifiedLink'
import ChangeCoach from './features/change-coach/ChangeCoach'
import Login from './features/auth/Login'
import HomeLegacy from './Home'
import UserHome from './features/home/UserHome'
import Error from './utils/Error'
// v2
import { AppLayout } from './layouts/AppLayout'
import { Login as LoginV2 } from './routes/login'
import { Onboarding } from './routes/onboarding'
import { loader as onboardingLoader } from './routes/onboarding/onboarding.loader'
import { queryClient } from './query'
import { OnboardingContextProvider } from './context/onboardingContext'
import { AppShell } from './layouts/AppShell'
import { Home } from './routes'
import { Chat as ChatV2 } from './routes/chat'
import { ChangeCoach as ChangeCoachV2 } from './routes/change-coach'
import { Book as BookV2 } from './routes/book'
import { loader as assessmentResultsLoader } from './routes/assessment-results/assessment-results.loader'
import { AssessmentResults as AssessmentResultsV2 } from './routes/assessment-results'
import { AssessmentResultsTab } from './routes/assessment-results/assessment-results.tab'
import { Sessions as BookingsV2 } from './routes/sessions'
import { loader as sessionsLoader } from './routes/sessions/sessions.loader'
import { loader as homeLoader } from './routes/index.loader'
import { Settings } from './routes/settings'
import { AccountSettings } from './routes/settings/account'
import { loader as accountLoader } from './routes/settings/account.loader'
import { HupoPerspectives } from './routes/hupo-perspectives'
import { loader as hupoPerspectivesLoader } from './routes/hupo-perspectives/hupo-perspectives.loader'
import { RespondentManagement } from './routes/hupo-perspectives/respondent-management'
import { HupoPerspectivesResults } from './routes/hupo-perspectives/results'
import { loader as respondentManagementLoader } from './routes/hupo-perspectives/respondent-management.loader'
import { Progress } from './routes/progress'
import { loader as progressLoader } from './routes/progress/progress.loader'
import { Notes } from './routes/notes'
import { MyNotes } from './routes/notes/my'
import { CoachNotes } from './routes/notes/coach'
import { loader as coachNotesLoader } from './routes/notes/coach.loader'
import { loader as myNotesLoader } from './routes/notes/my.loader'
import { HupoHighlights } from './routes/notes/hupohighlights'
import { loader as hupoHighlightsLoader } from './routes/notes/hupohighlights.loader'
import { NotFound } from './routes/not-found'

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <Error />,
    loader: (args) => {
      const url = new URL(args.request.url)
      const returnTo = url.searchParams.get('returnTo')
      // const useV2 = url.searchParams.get('useV2') === '1'

      // if (useV2) {
      //   Cookies.set('useV2', '1')
      // }

      if (returnTo) {
        return redirect(decodeURIComponent(returnTo))
      }
      return new Response(null, { status: 200 })
    },

    children: [
      {
        path: '/assessment/midpoint',
        element: <AssessmentIntro type="midpoint-assessment" />,
      },
      {
        path: '/assessment/midpoint/:index',
        element: <AssessmentRouter type="midpoint-assessment" />,
      },
      {
        path: '/assessment/view',
        element: <AssessmentResults />,
      },
      {
        path: '/assessment',
        element: <AssessmentIntro type="assessment" />,
      },
      {
        path: '/assessment/:index',
        element: <AssessmentRouter type="assessment" />,
      },
      {
        path: '/v2',
        element: <AppShell />,
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: <Home />,
            loader: homeLoader(queryClient),
          },
          {
            path: 'assessment-results',
            element: <AssessmentResultsV2 />,
            loader: assessmentResultsLoader(queryClient),
            children: [{ path: ':tab', element: <AssessmentResultsTab /> }],
          },
          {
            path: 'hupo-perspectives',
            element: <HupoPerspectives />,
            loader: hupoPerspectivesLoader(queryClient),
            children: [
              {
                index: true,
                loader: () => redirect('./respondent-management'),
              },
              { path: 'results', element: <HupoPerspectivesResults /> },
              {
                path: 'respondent-management',
                element: <RespondentManagement />,
                loader: respondentManagementLoader(queryClient),
              },
            ],
          },
          {
            path: 'hupo-perspectives-report',
            element: <AssessmentReport />,
          },
          {
            path: 'change-coach',
            element: <ChangeCoachV2 />,
          },
          {
            path: 'book',
            element: <BookV2 />,
          },
          {
            path: 'notes',
            element: <Notes />,
            children: [
              { index: true, loader: () => redirect('./coach') },
              {
                path: 'coach',
                element: <CoachNotes />,
                loader: coachNotesLoader(queryClient),
              },
              {
                path: 'my',
                element: <MyNotes />,
                loader: myNotesLoader(queryClient),
              },
              {
                path: 'hupohighlights/*',
                element: <HupoHighlights />,
                loader: hupoHighlightsLoader(queryClient),
              },
            ],
          },
          {
            path: 'progress',
            element: <Progress />,
            loader: progressLoader(queryClient),
          },
          {
            path: 'resources',
            element: <ResourcesLibraryPage />,
          },
          {
            path: 'ext/assessment',
            element: <AssessmentIntro type="assessment" />,
            children: [
              {
                path: ':index',
                element: <AssessmentRouter type="assessment" />,
              },
            ],
          },
          {
            path: 'bookings',
            element: <BookingsV2 />,
            loader: sessionsLoader(queryClient),
            // children: [
            //   {
            //     path: ':userId',
            //     element: <BookingsV2 />,
            //   },
            // ],
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              { index: true, loader: () => redirect('/settings/account') },
              {
                path: 'account',
                element: <AccountSettings />,
                loader: accountLoader(queryClient),
              },
            ],
          },
          {
            path: 'chat',
            element: <ChatV2 />,
          },
        ],
      },
      {
        path: '/',
        element: <HomeLegacy />,
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: <UserHome />,
          },
          {
            path: 'assessment-results',
            element: <AssessmentResults />,
          },
          {
            path: 'hupo-perspectives',
            element: <HupoPerspectivesPage />,
          },
          {
            path: 'hupo-perspectives-report',
            element: <AssessmentReport />,
          },
          {
            path: 'change-coach',
            element: <ChangeCoach />,
          },
          {
            path: 'book',
            element: <Book />,
          },
          {
            path: 'notes',
            element: <UserNotesWithSessionNotes />,
            children: [
              {
                path: 'hupohighlights/*',
                loader: () => redirect('/notes?tab=hupo-highlights'),
              },
            ],
          },
          {
            path: 'progress',
            element: <Stats />,
          },
          {
            path: 'resources',
            element: <ResourcesLibraryPage />,
          },
          {
            path: 'profile',
            element: <UserProfile />,
          },
          {
            path: 'ext/assessment',
            element: <AssessmentIntro type="assessment" />,
            children: [
              {
                path: ':index',
                element: <AssessmentRouter type="assessment" />,
              },
            ],
          },
          {
            path: 'bookings',
            element: <Bookings />,
            children: [
              {
                path: ':userId',
                element: <Bookings />,
              },
            ],
          },
          {
            path: 'chat',
            element: <Chat />,
            children: [
              {
                path: ':userId',
                element: <Chat />,
              },
            ],
          },
        ],
      },
      {
        path: '/app',
        children: [
          {
            path: 'distribution',
            element: <AppDistribution />,
          },
          {
            path: 'distribution/apk',
            element: <AppDistributionApk />,
          },
        ],
      },
      {
        path: '/links',
        children: [
          {
            path: 'unified/:companyFriendlyId',
            element: <UnifiedLink />,
          },
          {
            path: 'onboarding/:companyFriendlyId',
            element: <OnboardingLink />,
          },
        ],
      },
      {
        path: '/support',
        element: <Support />,
        children: [
          {
            path: ':userId/:userName/:userEmail/:userCompany/:userCoachEmail',
            element: <Support />,
          },
        ],
      },
      {
        path: 'v2/login',
        element: <LoginV2 />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: '/password_forgot',
        element: <PasswordForgot />,
      },
      {
        path: '/password_reset',
        children: [
          {
            path: ':token/:email',
            element: <PasswordReset />,
          },
        ],
      },
      {
        path: '/signup',
        element: <Signup />,
        children: [
          {
            path: ':invitationId',
            element: <Signup />,
          },
        ],
      },
      {
        path: '/book',
        children: [
          {
            path: ':invitationId',
            element: <BookPublic />,
          },
        ],
      },
      {
        path: '/bookings',
        children: [
          {
            path: 'review/:eventId/:coachName',
            element: <SessionReview />,
            children: [
              {
                path: ':rating',
                element: <SessionReview />,
              },
            ],
          },
        ],
      },
      {
        path: '/v2/onboarding',
        element: (
          <OnboardingContextProvider>
            <Onboarding />
          </OnboardingContextProvider>
        ),
        loader: onboardingLoader(queryClient),
        children: [
          { path: ':language/:companyId' },
          { path: ':language/:companyId/:departmentId' },
          { path: ':language/:companyId/user/:email' },
          { path: ':language/:companyId/:departmentId/user/:email' },
        ],
      },
      {
        path: '/onboarding',
        children: [
          {
            path: ':language/:companyId',
            element: <OnboardingLoadingScreen />,
          },
          {
            path: ':language/:companyId/:departmentId',
            element: <OnboardingLoadingScreen />,
          },
          {
            path: ':language/:companyId/user/:email',
            element: <OnboardingLoadingScreen />,
          },
          {
            path: ':language/:companyId/:departmentId/user/:email',
            element: <OnboardingLoadingScreen />,
          },
          {
            path: ':language/:companyId/step/:questionIndex',
            element: <OnboardingFlow />,
          },
          {
            path: ':language/:companyId/:departmentId/step/:questionIndex',
            element: <OnboardingFlow />,
          },
        ],
      },

      {
        path: '/survey',
        children: [
          {
            path: ':sessionId',
            element: <FeedbackSurvey />,
          },
        ],
      },
      {
        path: '/session-summary-feedback',
        children: [
          {
            path: ':eventId/:authorId/:rating',
            element: <SessionSummaryFeedback />,
          },
        ],
      },

      {
        path: '/session-summary',
        children: [
          {
            path: 'verify/:eventId',
            element: <HupoHighlightsVerify />,
          },
          {
            path: 'edit/:eventId',
            element: <HupoHighlightsEdit />,
          },
          {
            path: 'edit-success',
            element: <HupoHighlightsEditSuccess />,
          },
        ],
      },

      {
        path: '/session-feedback/user',
        children: [
          {
            path: ':eventId/:coachName',
            element: <PostSessionUserFeedback />,
            children: [
              {
                path: ':rating',
                element: <PostSessionUserFeedback />,
              },
            ],
          },
        ],
      },

      // In app meeting (dyte.io)
      {
        path: '/user',
        children: [
          {
            path: 'meeting/:eventId',
            element: (
              <ThemeProvider theme={appTheme}>
                <InAppMeeting participant="user" />
              </ThemeProvider>
            ),
          },
        ],
      },
      {
        path: '/coach',
        loader: () => redirect('https://coach.hupo.co/auth?mode=login'),
      },
      {
        path: '/coach/meeting/:eventId',
        element: (
          <ThemeProvider theme={appTheme}>
            <InAppMeeting participant="coach" />
          </ThemeProvider>
        ),
      },

      {
        path: '/users',
        children: [
          {
            path: ':userId/report',
            element: (
              <ThemeProvider theme={appTheme}>
                <AssessmentReportPDF />
              </ThemeProvider>
            ),
          },
        ],
      },

      {
        path: '/manager/assessment',
        children: [
          /* ----- Midpoint assessment ----- */
          {
            path: 'midpoint/:language/:userId',
            element: (
              <AssessmentIntro type="manager-midpoint-assessment" isManager />
            ),
          },
          {
            path: 'midpoint/:language/:userId/:index',
            element: (
              <AssessmentRouter type="manager-midpoint-assessment" isManager />
            ),
          },
          /* ----- Intermediate assessment ----- */
          {
            path: 'intermediate/:language/:userId/:authorEmail',
            element: (
              <AssessmentIntro type="manager-midpoint-assessment" isManager />
            ),
          },
          {
            path: 'intermediate/:language/:userId/:authorEmail/:index',
            element: (
              <AssessmentRouter type="manager-midpoint-assessment" isManager />
            ),
          },
          /* ---------- */
          {
            path: ':language/:userId',
            element: <AssessmentIntro type="manager-assessment" isManager />,
          },
          {
            path: ':language/:userId/:index',
            element: <AssessmentRouter type="manager-assessment" isManager />,
          },
        ],
      },
      {
        path: '/peer/assessment',
        children: [
          /* ----- Midpoint assessment ----- */
          {
            path: 'midpoint/:language/:userId/:authorEmail',
            element: <AssessmentIntro type="peer-midpoint-assessment" isPeer />,
          },
          {
            path: 'midpoint/:language/:userId/:authorEmail/:index',
            element: (
              <AssessmentRouter type="peer-midpoint-assessment" isPeer />
            ),
          },
          {
            path: 'midpoint/:language/:userId',
            element: <AssessmentIntro type="peer-midpoint-assessment" isPeer />,
          },
          {
            path: 'midpoint/:language/:userId/:index',
            element: (
              <AssessmentRouter type="peer-midpoint-assessment" isPeer />
            ),
          },
          /* ----- Intermediate assessment ----- */
          {
            path: 'intermediate/:language/:userId/:authorEmail',
            element: <AssessmentIntro type="peer-midpoint-assessment" isPeer />,
          },
          {
            path: 'intermediate/:language/:userId/:authorEmail/:index',
            element: (
              <AssessmentRouter type="peer-midpoint-assessment" isPeer />
            ),
          },
          /* ---------- */
          {
            path: ':language/:userId/:authorEmail',
            element: <AssessmentIntro type="peer-assessment" isPeer />,
          },
          {
            path: ':language/:userId/:authorEmail/:index',
            element: <AssessmentRouter type="peer-assessment" isPeer />,
          },
        ],
      },
      {
        path: '/reportee/assessment',
        children: [
          {
            path: 'intermediate/:language/:userId/:authorEmail',
            element: (
              <AssessmentIntro type="reportee-midpoint-assessment" isReportee />
            ),
          },
          {
            path: 'intermediate/:language/:userId/:authorEmail/:index',
            element: (
              <AssessmentIntro type="reportee-midpoint-assessment" isReportee />
            ),
          },
        ],
      },
      {
        path: '/peer-assessment',
        children: [
          {
            path: ':author',
            element: <PeerAssessmentPage />,
          },
          {
            path: ':author/u/q/leadership-values',
            element: <PeerAssessmentPage />,
          },
          {
            path: ':author/:user',
            element: <PeerAssessmentPage />,
          },
          {
            path: ':author/:user/:question',
            element: <PeerAssessmentPage />,
          },
        ],
      },

      /*  Redirects  */
      {
        path: '/feedback/:language/:companyId/:userId/:userName',
        loader: ({ params }) =>
          redirect(`/manager/assessment/${params.language}/${params.userId}`),
      },
      {
        path: '/onboarding/feedback/:language/:companyId/:userId/:userName',
        loader: ({ params }) =>
          redirect(`/manager/assessment/${params.language}/${params.userId}`),
      },

      // 404
      { path: '*', element: <NotFound /> },
    ],
  },
])
