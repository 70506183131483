import type { Action, ThunkAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import asssessmentSlice from '../features/assessment/assessmentSlice'
import authReducer from '../features/auth/authSlice'
import chatReducer from '../features/chat/chatSlice'
import socketSlice from '../features/connection/socketSlice'
import onboardingSlice from '../features/onboarding/onboardingSlice'
import feedbackSurveyReducer from '../features/survey/surveySlice'
import { onboardingSlice as onboardingSliceV2 } from '~/store/onboarding.slice'
import { preferencesSlice } from '~/store/preferences.slice'
import { api } from './services/api'
import npsReducer from '../features/nps/npsSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['onboarding', 'feedback/survey', 'survey', api.reducerPath],
}

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  chat: chatReducer,
  onboarding: onboardingSlice,
  onboardingV2: onboardingSliceV2.reducer,
  preferences: preferencesSlice.reducer,
  assessment: asssessmentSlice,
  socket: socketSlice,
  survey: feedbackSurveyReducer,
  nps: npsReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
})

export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>

export type RootState = ReturnType<typeof store.getState>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
