import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { usePostHog } from 'posthog-js/react'
import * as Sentry from '@sentry/react'

import { logout } from '../features/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'

export const useLogout = () => {
  const { authenticatedWith } = useAppSelector((state) => state.auth)
  const { logout: auth0Logout } = useAuth0()
  const dispatch = useAppDispatch()
  const posthog = usePostHog()

  return useCallback(async () => {
    dispatch(logout())
    await auth0Logout()
    posthog.reset(true)
    Sentry.setUser(null)
  }, [dispatch, auth0Logout, authenticatedWith])
}
