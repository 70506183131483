import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MfaHelper } from './MfaHelper'

/**
 * Component that provides MFA protection for routes
 * This component will redirect to the login page if MFA is in progress
 */
export const MfaProtection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const mfaIsProcessing = MfaHelper.isProcessingMfa()

  useEffect(() => {
    if (mfaIsProcessing) {
      console.log('MfaProtection: MFA is in progress, redirecting to login page')
      navigate('/login')
    }
  }, [mfaIsProcessing, navigate])

  return <>{children}</>
}

export default MfaProtection
