import { Box, Divider, Flex, Grid, Spinner, Text } from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetCreditsQuery } from '../../app/services/api'
import CardCoach from '../../CardCoach'
import { useAuth } from '../../hooks/useAuth'
import Coaches from '../coaches/Coaches'
import SessionNotes from '../customer/SessionNotes'
import GrowthArea from '../growth-area/GrowthArea'

const UserHome: FC = () => {
  const { user } = useAuth()
  const usersCoach = useMemo(() => user?.coach, [user])
  const { t } = useTranslation()
  const { data: userCredits, isFetching: creditsFetching } =
    useGetCreditsQuery()

  return (
    <Grid
      templateColumns={['1fr', '1fr', '1fr', '1fr', '400px 1fr']}
      gap="10px"
      alignItems="flex-start"
    >
      {user?.coach ? (
        <Grid rowGap="10px" rounded={'lg'} data-testid="home-my-coach">
          <Box bg={'white'}>
            <Box w={'full'} px={6} pt={4} pb={2}>
              <Text fontWeight="700" fontSize="22px">
                {t('my_coach')}
              </Text>
            </Box>
            <Divider />
            <CardCoach user={user} usersCoach={usersCoach} />
          </Box>

          {!creditsFetching && userCredits?.total && (
            <Grid gap="10px" bg={'white'} rounded={'lg'}>
              <Box w={'full'} px={6} pt={4} pb={2}>
                <Text fontWeight="700" fontSize="22px">
                  {t('credits')}
                </Text>
              </Box>
              <Divider />
              {creditsFetching ? (
                <Spinner />
              ) : (
                // Show credits used and total: 5/10 and sessions under the text
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={4}
                >
                  <Text
                    fontSize="3xl"
                    fontWeight="700"
                    color="gray.600"
                    mb={2}
                  >{`${userCredits?.used} / ${userCredits?.total}`}</Text>
                  <Text fontSize="sm" color="gray.700">
                    {t('sessions')}
                  </Text>
                </Flex>
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <Coaches />
      )}
      <Grid gap="10px">
        <GrowthArea />
        {user && <SessionNotes />}
      </Grid>
    </Grid>
  )
}

export default UserHome
