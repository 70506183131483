/*
 * This file contains helper functions to manage the MFA processing state using sessionStorage.
 */
export class MfaHelper {
  static clearMfaProcessingState = () => {
    sessionStorage.removeItem('mfa_required')
    sessionStorage.removeItem('mfa_user_id')
    sessionStorage.removeItem('mfa_code_requested')
  }

  static isProcessingMfa = () => {
    const mfaRequired = sessionStorage.getItem('mfa_required') === 'true'
    return mfaRequired
  }

  static setMfaProcessingState = (userId: string) => {
    sessionStorage.setItem('mfa_required', 'true')
    sessionStorage.setItem('mfa_user_id', userId)
  }

  static markMfaCodeRequested = () => {
    sessionStorage.setItem('mfa_code_requested', 'true')
  }

  static resetMfaCodeRequested = () => {
    sessionStorage.removeItem('mfa_code_requested')
  }

  static hasRequestedMfaCode = () => {
    return sessionStorage.getItem('mfa_code_requested') === 'true'
  }

  static getMfaUserId = () => {
    return sessionStorage.getItem('mfa_user_id')
  }
}
