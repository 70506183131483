import { Outlet } from 'react-router-dom'
import { Auth0ProviderWithRedirectCallback } from '~/Auth0ProviderWithRedirectCallback'
import MfaProtection from '../features/auth/mfa/MfaProtection'

export const AppLayout: React.FC = () => {
  return (
    <Auth0ProviderWithRedirectCallback>
      <MfaProtection>
        <Outlet />
      </MfaProtection>
    </Auth0ProviderWithRedirectCallback>
  )
}
