import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useRequestMfaCodeMutation,
  useVerifyMfaMutation,
} from '../../../app/services/api'
import { useLocale } from '../../../hooks/useLocale'
import { useMixpanel } from '../../../utils/MixpanelContext'
import { MfaHelper } from './MfaHelper'

interface MfaVerificationProps {
  userId: string
  onSuccess: (token: string) => void
  redirectTo: string
}

export const MfaVerification = ({
  userId,
  onSuccess,
  redirectTo,
}: MfaVerificationProps) => {
  const { t } = useLocale()
  const toast = useToast()
  const navigate = useNavigate()
  const mixpanel = useMixpanel()
  const [code, setCode] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [verifyMfa] = useVerifyMfaMutation()
  const [requestMfaCode] = useRequestMfaCodeMutation()

  const handleSubmit = async () => {
    if (!code) return

    setIsSubmitting(true)
    try {
      console.log('Verifying MFA code:', {
        userId,
        code: code.length > 0 ? '******' : '',
      })
      const result = await verifyMfa({ userId, code }).unwrap()
      console.log('Verify MFA result:', result)

      if (result.verified && result.token) {
        console.log('MFA verification successful, proceeding with login')

        // Clear all MFA-related session storage
        MfaHelper.clearMfaProcessingState()

        mixpanel.track('mfa_verification_success')
        onSuccess(result.token)
        navigate(redirectTo)
      } else {
        console.error('MFA verification failed: invalid or missing token')
        toast({
          status: 'error',
          title: t('error'),
          description: t('invalid_verification_code'),
          isClosable: true,
        })
        mixpanel.track('mfa_verification_failed', { reason: 'invalid_code' })
      }
    } catch (err: any) {
      console.error('MFA verification error:', err)
      toast({
        status: 'error',
        title: t('error'),
        description: err.data?.message || t('there_was_an_error_verifying_mfa'),
        isClosable: true,
      })
      mixpanel.track('mfa_verification_failed', { message: err.data?.message })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleResendCode = async () => {
    try {
      console.log('Resending MFA code for user ID:', userId)

      // Remove the stored flag to allow requesting a new code
      MfaHelper.resetMfaCodeRequested()

      const result = await requestMfaCode({ userId }).unwrap()
      console.log('MFA code resend successful:', result)

      // Mark that we've requested a code
      MfaHelper.markMfaCodeRequested()

      toast({
        status: 'success',
        title: t('success'),
        description: t('code_resent_successfully'),
        isClosable: true,
      })
      mixpanel.track('mfa_code_resent')
    } catch (err: any) {
      console.error('Error resending MFA code:', err)
      toast({
        status: 'error',
        title: t('error'),
        description:
          err.data?.message || t('there_was_an_error_resending_code'),
        isClosable: true,
      })
      mixpanel.track('mfa_code_resend_failed', { message: err.data?.message })
    }
  }

  return (
    <Box
      maxW="md"
      mx="auto"
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="md"
      bg="white"
    >
      <Stack spacing={4}>
        <Heading size="lg">{t('verification_required')}</Heading>
        <Text>{t('mfa_verification_instruction')}</Text>

        <FormControl id="verificationCode">
          <FormLabel>{t('verification_code')}</FormLabel>
          <Input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={t('enter_verification_code')}
          />
        </FormControl>

        <Button
          colorScheme="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          isDisabled={!code || isSubmitting}
        >
          {t('verify')}
        </Button>

        <Button
          variant="link"
          onClick={handleResendCode}
          isDisabled={isSubmitting}
        >
          {t('resend_code')}
        </Button>
      </Stack>
    </Box>
  )
}

export default MfaVerification
