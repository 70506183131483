import React, { useEffect } from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react'
import MfaVerification from './MfaVerification'
import { User, GetMeResponse } from '../../../types/api'
import { MfaHelper } from './MfaHelper'

interface MfaLoginHandlerProps {
  user: User | GetMeResponse
  onSuccess: (token: string) => void
  redirectTo: string
}

/**
 * Dedicated component for handling MFA verification
 * This helps avoid re-render issues in the main Login component
 */
export const MfaLoginHandler: React.FC<MfaLoginHandlerProps> = ({
  user,
  onSuccess,
  redirectTo,
}) => {
  const bgColor = useColorModeValue('gray.50', 'gray.800')

  console.log('MfaLoginHandler rendering for user:', user._id)

  // Ensure the MFA state is persisted in session storage
  useEffect(() => {
    // Store MFA state for persistence across reloads/redirects
    MfaHelper.setMfaProcessingState(user._id)
  }, [user._id])

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={bgColor}>
      <MfaVerification
        userId={user._id}
        onSuccess={onSuccess}
        redirectTo={redirectTo}
      />
    </Flex>
  )
}

export default MfaLoginHandler
