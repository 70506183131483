import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePostHog } from 'posthog-js/react'
import { useLoginMutation } from '../../app/services/api'
import { useAppDispatch } from '../../app/store'
import { saveUserToken } from '../../features/auth/authSlice'
import { useAuth } from '../../hooks/useAuth'
import { useLocale } from '../../hooks/useLocale'
import type { GetMeResponse, LoginRequest, User } from '../../types/api'
import { useMixpanel } from '../../utils/MixpanelContext'
import MfaLoginHandler from './mfa/MfaLoginHandler'
import { MfaHelper } from './mfa/MfaHelper'

function PasswordInput({
  name,
  value,
  onChange,
}: {
  name: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}) {
  const { t } = useLocale()
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        type={show ? 'text' : 'password'}
        placeholder={t('enter_password')}
        name={name}
        value={value}
        onChange={onChange}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? t('hide') : t('show')}
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

export default function Login() {
  const { t } = useLocale()
  const navigate = useNavigate()
  const toast = useToast()
  const { user } = useAuth()
  const { state }: any = useLocation()
  const mixpanel = useMixpanel()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef() as MutableRefObject<HTMLInputElement>
  const dispatch = useAppDispatch()
  const bgColor = useColorModeValue('gray.50', 'gray.800')
  const boxBgColor = useColorModeValue('white', 'gray.700')

  const redirectTo = state?.from ? state.from : '/'
  const [login] = useLoginMutation()
  const posthog = usePostHog()

  const email = Cookies.get('AmiEmail') || ''
  const password = Cookies.get('AmiPassword') || ''
  const isCookiesSet = email && password

  // States for MFA handling
  const [requiresMfa, setRequiresMfa] = useState(false)
  const [currentUser, setCurrentUser] = useState<User | GetMeResponse | null>(
    null
  )
  const [isProcessingLogin, setIsProcessingLogin] = useState(false)

  // Check for MFA state on mount
  useEffect(() => {
    mixpanel.track('login_open')

    // Check if we have a pending MFA verification
    const mfaRequired = MfaHelper.isProcessingMfa()
    const mfaUserId = MfaHelper.getMfaUserId()

    console.log('Initial MFA check:', { mfaRequired, mfaUserId })

    if (mfaRequired && mfaUserId) {
      // We need to restore the MFA state
      console.log('Restoring MFA state from session storage')

      // Fetch user data if needed (minimal data for MFA verification)
      setRequiresMfa(true)
      setIsProcessingLogin(true)
      setCurrentUser({
        _id: mfaUserId,
        // Include minimal required properties
        email: '',
        profile: { name: '' },
        company: { _id: '', name: '' },
        isOnline: false,
      } as User)
    }
  }, [])

  const [formState, setFormState] = useState<LoginRequest>({
    email: isCookiesSet ? email : '',
    password: isCookiesSet ? password : '',
  })
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(
    !isCookiesSet
  )

  // Check if user's company requires MFA (wayfinding logic)
  const shouldRequireMfa = useCallback((user: User | GetMeResponse) => {
    console.log('shouldRequireMfa', user)
    // Handle different object structures while checking MFA requirements
    const hasMfaEnabled = 'mfa' in user && user.mfa?.enabled === true
    const companyRequiresMfa =
      'company' in user &&
      user.company &&
      'requireMfa' in user.company &&
      user.company.requireMfa === true

    return hasMfaEnabled || companyRequiresMfa
  }, [])

  // Common function to handle login success tracking and navigation
  const handleLoginSuccess = useCallback(
    (user: User | GetMeResponse) => {
      // Use type guard to safely check isOnboardingCompleted
      const isOnboardingCompleted =
        'isOnboardingCompleted' in user ? user.isOnboardingCompleted : false

      // Track login success in Mixpanel
      mixpanel.identify(user._id)
      mixpanel.people.set('email', user.email)
      mixpanel.set_group('company', user.company?.name || 'Unknown Company')
      mixpanel.set_group('coach', user.coach?.email || 'Unknown Coach')

      // Track login success in PostHog
      posthog.identify(user._id, {
        email: user.email,
        name: user.profile.name,
        company: user.company?.name || 'Unknown Company',
        companyId: user.company?._id,
        department: user.department?.name,
        departmentId: user.department?._id,
        coach: user.coach?.email || 'Unknown Coach',
      })

      // Navigate based on onboarding status
      if (isOnboardingCompleted) {
        navigate(redirectTo)
      } else {
        navigate(
          `/onboarding/${user.language}/${user.company._id}${
            user.department?._id ? '/' + user.department?._id : ''
          }?questionIndex=${user.questionIndex}`
        )
      }
    },
    [mixpanel, posthog, navigate, redirectTo]
  )

  const handleMfaSuccess = useCallback(
    (token: string) => {
      console.log(
        'MFA success with token:',
        token ? 'token-received' : 'no-token'
      )

      // Clear MFA state from session storage
      MfaHelper.clearMfaProcessingState()

      // Clear processing flags
      setIsProcessingLogin(false)
      setRequiresMfa(false)

      if (!token) {
        console.error('No token provided after MFA verification')
        toast({
          status: 'error',
          title: t('error'),
          description: t('missing_auth_token'),
          isClosable: true,
        })
        return
      }

      if (currentUser) {
        console.log('Completing MFA login for user:', currentUser._id)
        // Save token after MFA verification
        dispatch(saveUserToken(token, 'local'))

        // Track MFA-specific event before handling general login success
        mixpanel.track('login_success_with_mfa')

        // Reuse the common login success handler
        handleLoginSuccess(currentUser)
      }
    },
    [currentUser, dispatch, mixpanel, toast, t, handleLoginSuccess]
  )

  const submitForm = useCallback(async () => {
    // Set processing flag to prevent redirects
    setIsProcessingLogin(true)

    try {
      const adminSecret = document.getElementById(
        'adminSecret'
      ) as HTMLInputElement

      const formStateWithSecret = {
        ...formState,
        adminSecret: adminSecret?.value,
      }
      const response = await login(formStateWithSecret).unwrap()
      console.log('Full login response:', response)

      // Extract user from response
      const { user } = response
      console.log('Got user from login:', user)

      // First check if the backend explicitly requires MFA
      let isMFARequired = false
      if (response.requireMfa === true) {
        console.log('Backend explicitly requires MFA')
        isMFARequired = true
      } else {
        // Fall back to checking user properties
        isMFARequired = shouldRequireMfa(user)
        console.log('MFA required based on user properties:', isMFARequired)
      }

      // Check if MFA is required for this user
      if (isMFARequired) {
        console.log('MFA IS REQUIRED - setting MFA state')

        // IMPORTANT: Don't make any authenticated API calls at this point
        // We need to show the MFA verification screen first

        // Store user and set state
        setCurrentUser(user)

        // We need to set requiresMfa synchronously to prevent any redirects
        setRequiresMfa(true)

        // Set MFA state to processing to persist the MFA state across page reloads/redirects
        MfaHelper.setMfaProcessingState(user._id)

        mixpanel.track('mfa_required')
        console.log('MFA state set:', {
          requiresMfa: true,
          currentUser: user._id,
        })

        // Force React to render MFA screen immediately
        await new Promise((resolve) => setTimeout(resolve, 10))

        // Exit early to prevent regular login flow
        return
      } else {
        // No MFA required, proceed with normal login
        // Make sure any leftover MFA state is cleared
        MfaHelper.clearMfaProcessingState()
        setRequiresMfa(false)

        // Save token and dispatch user info
        if (response.token) {
          dispatch(saveUserToken(response.token, 'local'))
        }

        // Track standard login success
        mixpanel.track('login_success')

        // Handle login success tracking and navigation
        handleLoginSuccess(user)
      }
    } catch (err: any) {
      // Always clear login processing state on error
      setIsProcessingLogin(false)

      // If there was an error and we were trying to do MFA, clear those flags too
      if (requiresMfa) {
        setRequiresMfa(false)
        MfaHelper.clearMfaProcessingState()
      }

      toast({
        status: 'error',
        title: t('error'),
        description: err.data?.message || t('there_was_an_error'),
        isClosable: true,
      })
      mixpanel.track('login_fail', { message: err.data?.message })
      if (err?.data?.canLoginAsCoach) {
        onOpen()
      }
    } finally {
      // Only clear processing flag if we're not showing MFA screen
      // We need to keep it for MFA flow
      if (!requiresMfa) {
        setIsProcessingLogin(false)
      }
    }
  }, [formState, login, navigate, toast, t, redirectTo, shouldRequireMfa])

  useEffect(() => {
    // Check session storage first for persisted MFA state
    const mfaRequired = MfaHelper.isProcessingMfa()

    console.log(
      'Navigation effect - isProcessingLogin:',
      isProcessingLogin,
      'requiresMfa:',
      requiresMfa,
      'mfaRequired (session):',
      mfaRequired
    )

    // Skip automatic redirection if MFA is required or if login is being processed
    if (requiresMfa || mfaRequired || isProcessingLogin) {
      console.log(
        'Skipping automatic redirect - MFA required or login processing'
      )
      return
    }

    // Normal login flow for users without MFA
    if (user && user.isOnboardingCompleted) {
      console.log('Navigating to main app - completed onboarding')
      navigate(redirectTo)
    } else if (user && !user?.isOnboardingCompleted) {
      console.log('Navigating to onboarding')
      navigate(
        `/onboarding/${user.language}/${user.company._id}${
          user.department?._id ? '/' + user.department?._id : ''
        }?questionIndex=${user.questionIndex}`
      )
    } else if (isCookiesSet) {
      console.log('Auto-submitting form using saved credentials')
      submitForm()
    }
  }, [
    navigate,
    user,
    submitForm,
    isCookiesSet,
    redirectTo,
    requiresMfa,
    isProcessingLogin,
  ])

  useEffect(() => {
    setIsButtonDisabled(!(formState?.email.trim() && formState?.password))
  }, [formState])

  const handleChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }))

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault()
      submitForm()
    },
    [submitForm]
  )

  const onRedirectToCoachLogin = () => {
    navigate('/coach-login')
  }

  // Check if we should show MFA verification before rendering
  console.log(
    'Render decision - requiresMfa:',
    requiresMfa,
    'currentUser:',
    currentUser ? 'exists' : 'null',
    'isProcessingLogin:',
    isProcessingLogin
  )

  // For regular (non-MFA) users, we should let the normal login flow work
  const mfaRequired = MfaHelper.isProcessingMfa()

  // Render MFA verification if required
  if ((requiresMfa || mfaRequired) && currentUser) {
    console.log('Rendering MFA verification screen')
    return (
      <MfaLoginHandler
        user={currentUser}
        onSuccess={handleMfaSuccess}
        redirectTo={redirectTo}
      />
    )
  }

  console.log('Rendering regular login form')

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={bgColor}>
      <form onSubmit={onSubmit}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>{t('sign_in')}</Heading>
            <Text fontSize={'lg'} color={'gray.600'} align={'center'}>
              {t('ami_is_100%_secure_and_confidential_so')}
            </Text>
          </Stack>
          <Box rounded={'lg'} bg={boxBgColor} boxShadow={'lg'} p={8}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>{t('email')}</FormLabel>
                <Input
                  onChange={handleChange}
                  name="email"
                  value={formState.email}
                  type="text"
                  placeholder={t('email')}
                  autoFocus
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>{t('password')}</FormLabel>
                <PasswordInput
                  onChange={handleChange}
                  name="password"
                  value={formState.password}
                />
              </FormControl>
              <FormControl id="adminSecret">
                <Input name="adminSecret" value="" type="hidden" />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                >
                  <Checkbox>{t('remember_me')}</Checkbox>
                  <Link href="/password_forgot" color={'blue.400'}>
                    {t('forgot_password?')}
                  </Link>
                </Stack>
                <Button
                  disabled={isButtonDisabled}
                  colorScheme="primary"
                  onClick={onSubmit}
                  type="submit"
                >
                  {t('sign_in')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('trying_to_login_as_coach')}
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button onClick={onClose}>{t('cancel')}</Button>
              <Button
                colorScheme="blue"
                onClick={onRedirectToCoachLogin}
                ml={3}
              >
                {t('go_to_coach_login')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  )
}
