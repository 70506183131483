/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { IconType } from 'react-icons'
import { AiOutlineSchedule } from 'react-icons/ai'
import { BiPhoneCall } from 'react-icons/bi'
import { FiChevronDown, FiHome, FiMenu, FiUser } from 'react-icons/fi'
import { MdEventNote, MdOutlineAssessment } from 'react-icons/md'
import {
  Link,
  Navigate,
  NavLink,
  Outlet,
  useSearchParams,
} from 'react-router-dom'

import { IoStatsChartOutline } from 'react-icons/io5'
import { MdBarChart } from 'react-icons/md'
import { VscFolderLibrary } from 'react-icons/vsc'
import { useAppDispatch, useAppSelector } from './app/hooks'
import {
  useGetAssessmentDatesQuery,
  useGetLeadershipValueScoresQuery,
  useGetUserInfoQuery,
} from './app/services/api'
import { saveUserToken } from './features/auth/authSlice'
import UserTimezoneDialog from './features/profile/TimezoneDialog'
import WelcomePopup from './features/profile/WelcomePopup'
import './Home.css'
import { SocketContext } from './hooks/socket'
import { useE2Etest } from './hooks/useE2Etest'
import { useLocale } from './hooks/useLocale'
import { useLogout } from './hooks/useLogout'
import { useUserDataWithAuth0Token } from './hooks/useUserDataWithAuth0Token'
import { User } from './types/api'
import { Logo } from './utils/Logo'
import { useMixpanel } from './utils/MixpanelContext'
// import { USING_V2 } from './app/constants'

interface LinkItemProps {
  name: string
  icon: IconType
  href: string
}

const getLinkItems = (
  hupoPerspectivesEnabled: boolean
): Array<LinkItemProps> => {
  return [
    { name: 'home', icon: FiHome, href: '/' },
    // { name: 'chat', icon: MdChat, href: '/chat' },
    { name: 'make_appointment', icon: BiPhoneCall, href: '/book' },
    { name: 'appointments', icon: AiOutlineSchedule, href: '/bookings' },
    hupoPerspectivesEnabled
      ? {
          name: 'hupo_perspectives',
          icon: MdBarChart,
          href: '/hupo-perspectives',
        }
      : {
          name: 'assessment-results',
          icon: MdOutlineAssessment,
          href: '/assessment-results',
        },
    {
      name: 'notes',
      icon: MdEventNote,
      href: '/notes',
    },
    {
      name: 'progress',
      icon: IoStatsChartOutline,
      href: '/progress',
    },
  ]
}

function Home() {
  const { t } = useLocale()
  const posthog = usePostHog()
  const user = useAppSelector((state) => state.auth.user)
  const { getAccessTokenSilently } = useAuth0()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isWelcomeOpen,
    onOpen: onWelcomeOpen,
    onClose: onWelcomeClose,
  } = useDisclosure()
  const dispatch = useAppDispatch()
  const mixpanel = useMixpanel()
  const [searchParams] = useSearchParams()
  const { getUserDataWithAuth0Token } = useUserDataWithAuth0Token()

  const fromAuth0 = useMemo(
    () => searchParams.get('fromAuth0') === 'true',
    [searchParams]
  )

  useEffect(() => {
    window._chatlio?.show()
  }, [])

  useEffect(() => {
    // if (USING_V2) {
    //   location.href = '/v2'
    // }

    if (fromAuth0) {
      getAccessTokenSilently().then((token) => {
        dispatch(saveUserToken(token, 'auth0'))
        getUserDataWithAuth0Token(token)
      })
    }
  }, [dispatch, getAccessTokenSilently, getUserDataWithAuth0Token, fromAuth0])

  // intentionally not used
  const { data: _ } = useGetUserInfoQuery({}, { skip: fromAuth0 && !user })

  const socket: any = useContext(SocketContext)

  const isResourceLibraryEnabled = !!useFeatureFlagEnabled('resource_library')

  const isE2Etest = useE2Etest()

  useEffect(() => {
    // Check if user has limits and show warning popup if they do
    if (!Cookies.get('welcomePopup') && !isE2Etest) {
      onWelcomeOpen()
      Cookies.set('welcomePopup', 'true', { expires: 1 })
    }

    // Identify user in Chatlio
    if (window._chatlio && user?._id) {
      window._chatlio.identify(user?._id, {
        name: user?.profile.name,
        email: user?.email,
        company: user?.company?.name || 'Unknown',
        coach: user?.coach?.email,
      })
    }

    return () => {
      socket.removeAllListeners()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Set Mixpanel identifiers if not set
    if (user && !mixpanel.get_property('company')) {
      mixpanel.identify(user._id)
      mixpanel.people.set('email', user.email)
      mixpanel.set_group('company', user.company?.name || 'Unknown')
    }

    if (user) {
      Sentry.setUser({
        id: user._id,
        username: user.profile.name,
        email: user.email,
      })
    }

    if (user) {
      posthog.identify(user._id, {
        email: user.email,
        name: user.profile.name,
        company: user.company?.name,
        companyId: user.company?._id,
        department: user.department?.name,
        departmentId: user.department?._id,
        coach: user.coach?.email,
      })
    }
  }, [user, mixpanel, posthog])

  if (!user && location.pathname !== '/login') {
    return (
      <Navigate
        to="/login"
        state={{
          from: `${location.pathname}${
            searchParams.toString() ? '?' + searchParams.toString() : ''
          }`,
        }}
      />
    )
  }

  const hupoPerspectivesEnabled = !!(user?.values && user.values.length > 0)

  return (
    <Box minH="100vh" bg={'gray.100'}>
      <UserTimezoneDialog />
      <SidebarContent
        t={t}
        onClose={() => onClose}
        hupoPerspectivesEnabled={hupoPerspectivesEnabled}
        display={{ base: 'none', md: 'block' }}
        resourceLibraryEnabled={isResourceLibraryEnabled}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            t={t}
            onClose={onClose}
            hupoPerspectivesEnabled={hupoPerspectivesEnabled}
            resourceLibraryEnabled={isResourceLibraryEnabled}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav t={t} onOpen={onOpen} user={user} socket={socket} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
        <WelcomePopup
          isOpen={isWelcomeOpen}
          onClose={onWelcomeClose}
          user={user}
        />
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
  t: any
  hupoPerspectivesEnabled: boolean
  resourceLibraryEnabled: boolean
}

const SidebarContent = ({
  onClose,
  t,
  hupoPerspectivesEnabled,
  resourceLibraryEnabled,
  ...rest
}: SidebarProps) => {
  const linkItems = getLinkItems(hupoPerspectivesEnabled)

  if (resourceLibraryEnabled) {
    linkItems.push({
      name: 'resources',
      icon: VscFolderLibrary,
      href: '/resources',
    })
  }
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        justify={{ base: 'space-between', md: 'center' }}
        mx="8"
      >
        <Logo />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {linkItems.map((link) => (
        <NavItem
          href={link.href}
          key={link.name}
          icon={link.icon}
          onClick={onClose}
        >
          {t(link.name)}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  href: string
}

const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  return (
    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to={href}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'primary.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
  user: User | null
  socket: any
  t: any
}
const MobileNav = ({ onOpen, user, socket, t, ...rest }: MobileProps) => {
  const { data: assessmentDates } = useGetAssessmentDatesQuery()

  // Not used in this page, just to warm up the cache, to be used in the assessment page
  const [_initialAssessmentEnabled, setInitialAssessmentEnabled] =
    useState(false)
  const [_midpointAssessmentEnabled, setMidpointAssessmentEnabled] =
    useState(false)

  const [leadershipValues, setLeadershipValues] = useState([])
  const { data: leadershipValueScores } = useGetLeadershipValueScoresQuery({})

  const logout = useLogout()

  const showMidpointAssessmentPendingCTA = useMemo(
    () =>
      assessmentDates?.midpointAssessmentDue ||
      assessmentDates?.midpointAssessmentRequired,
    [assessmentDates]
  )

  useEffect(() => {
    if (assessmentDates) {
      setInitialAssessmentEnabled(
        !assessmentDates.initialAssessmentCompleted &&
          assessmentDates.initialAssessmentRequired
      )
      setMidpointAssessmentEnabled(
        !assessmentDates.midpointAssessmentCompleted &&
          assessmentDates.midpointAssessmentRequired
      )
      setLeadershipValues(assessmentDates.leadershipValues)
    }
  }, [assessmentDates])

  const hupoPerspectivesEnabled = !!(user?.values && user.values.length > 0)

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Logo display={{ base: 'flex', md: 'none' }} />

      <HStack spacing={{ base: '0', md: '6' }} style={{ zIndex: 100 }}>
        <Flex alignItems={'center'}>
          <HStack>
            {/* <NavLink exact activeClassName="active" to={'/coaching-guide'}>
              <Button me={4} variant="outline" colorScheme={'primary'}>
                {t('coaching_guide')}
              </Button>
            </NavLink> */}
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Icon as={FiUser} fontSize={{ base: '32', md: '20' }} />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">{user?.profile?.name}</Text>
                    <Text fontSize="xs" color="gray.600">
                      {user?.company?.name}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                <Link to="/profile">
                  <MenuItem>{t('profile')}</MenuItem>
                </Link>
                <Link to="/change-coach">
                  <MenuItem>{t('change_coach')}</MenuItem>
                </Link>
                <a
                  href="https://main.hupo.co/core/user-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MenuItem>{t('user_agreement')}</MenuItem>
                </a>
                <a
                  href={
                    showMidpointAssessmentPendingCTA
                      ? '/assessment/midpoint'
                      : '/assessment'
                  }
                >
                  {hupoPerspectivesEnabled &&
                    leadershipValueScores?.scores &&
                    Object.keys(leadershipValueScores.scores.self).length ===
                      0 && (
                      <MenuItem>
                        {showMidpointAssessmentPendingCTA
                          ? t('complete_competency_review')
                          : leadershipValues && leadershipValues.length > 0
                          ? t('complete_hupo_perspectives')
                          : t('complete_competency_review')}
                      </MenuItem>
                    )}
                </a>
                <MenuDivider />
                <MenuItem onClick={logout}>{t('sign_out')}</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </HStack>
    </Flex>
  )
}

export default Home
